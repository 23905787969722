import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

const CourseCartPriceDisplay = ({
  userCurrencySYmbol,
  courseCurrencySymbol,
  coursePrice,
  coursePriceKuwait,
  cart_id,
  course,
}) => {
  const dispatch = useDispatch({});
  const [amount, setAmount] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (cart_id) {
      if (userCurrencySYmbol === courseCurrencySymbol) {
        // If the currency symbols match, just accumulate the price
        dispatch({
          type: "CARTCHECKOUTPRICETOTOAL",
          payload: {
            cartTotalPrice: coursePrice,
            course: {
              ...course,
              subscription_price: coursePrice,
              currency_symbol: userCurrencySYmbol,
            },
          },
        });
      } else {
        // If the currency symbols don't match, fetch conversion rate
        getCurrencyValue();
      }
    }
  }, [cart_id, course]);

  const getCurrencyValue = async () => {
    setLoading(true);

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_CURRENCY_API_URL}/convert?apikey=${process.env.REACT_APP_CURRENCY_API_KEY}&currencies=${userCurrencySYmbol}&base_currency=KWD&value=${coursePriceKuwait}`
      );

      // Get the converted price
      const convertedPrice = res.data.data[userCurrencySYmbol].value;
      // Dispatch the updated total price to Redux
      dispatch({
        type: "CARTCHECKOUTPRICETOTOAL",
        payload: {
          cartTotalPrice: convertedPrice,
          course: {
            ...course,
            subscription_price: convertedPrice,
            currency_symbol: userCurrencySYmbol,
          }, // Use the updated ref value
        },
      });

      setAmount({
        value: convertedPrice,
        currency_cd: userCurrencySYmbol,
      });
    } catch (err) {
      console.error(err);
      alert("Currency converter error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {userCurrencySYmbol === courseCurrencySymbol ? (
        `${coursePrice} ${courseCurrencySymbol}`
      ) : (
        <div>
          {loading && (
            <span className="loader-spinner loader-spinner-additional"></span>
          )}
          {!loading &&
            `${parseFloat(amount.value).toFixed(3)} ${amount.currency_cd}`}
        </div>
      )}
    </>
  );
};

export default CourseCartPriceDisplay;
