import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineClose, AiOutlineBell } from "react-icons/ai";
import { HiBellAlert } from "react-icons/hi2";
import { BsArrowLeft } from "react-icons/bs";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import ReactPlayer from "react-player";
import { fetchStory } from "../functions/story/story";

import {
  getDataPostWithOutHeader,
  getDataPostWithHeader,
  getRequestedDataWithHeader,
} from "../functions/editProfile/editProfile";
import LoadingCard from "../Components/cards/LoadingCard";
import { Link } from "react-router-dom";

import defualtImg from "../images/Read&ShareThumbnail.png";
import defualtProfile from "../images/default.jpeg";
import Model from "../utils/models/DefaultModel";
import HlsPlayer from "../Components/players/HlsPlayer";
import VPlayer from "../Components/players/VPlayer";
import NormalVDPlayer from "../Components/players/NormalVDPlayer";
import UserStoryAttachments from "../Components/profile/UserStoryAttachments";
const weburl = process.env.REACT_APP_BACKEND_URL;

const ViewProfileStory = ({ user }) => {
  const params = useParams();

  const dateoptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const [story, setStory] = useState({});
  const [youtubeVideoId, setYoutubeVideoID] = useState({});
  const [languageStory, setLanguageStory] = useState([]);
  const [attachments, setAttachments] = useState(false);
  const [HLSVIDEOPLAYER, setHLSVIDEOPLAYER] = useState(true);
  const [qualities, setQualities] = useState([]);
  const [index, setIndex] = useState(0);
  const [storyPage, setStoryPage] = useState(1);
  const [totalStoryPage, setTotalStoryPage] = useState();
  const [loading, setLoading] = useState();
  const [showSignin, setShowSignin] = useState(false);
  const [showDummyCard, setShowDummyCard] = useState(false);
  const [isFollow, setIsFollow] = useState(false);
  const [isNotifyMeAll, setIsNotifyMeAll] = useState(false);
  const dispatch = useDispatch();
  const { CatStories } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    document.body.style.backgroundColor = "white";

    if (user.isLogin) {
      storyDetails(params.id);
    }
  }, [params, user]);

  useEffect(() => {
    if (story.story_url_quality_versions)
      if (story.story_url_quality_versions.url_360) {
        let qualties = [];
        for (let i in story.story_url_quality_versions) {
          qualties.push({
            quality: i.split("_")[1] + "p",
            url: story.story_url_quality_versions[i],
          });
        }

        setQualities(qualties.reverse());
      }
  }, [story]);

  const storyDetails = async (story_id) => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/getUserStory?story_id=${story_id}&user_id=${user.userData.loginUser._id}`;

      const res = await getRequestedDataWithHeader(url, user.userData.token);
      console.log("stories", res.data.body);
      if (res.data.body.story_type === "video_url") {
        console.log(res.data.body.story_type);
        const videoId = getId(res.data.body.story_url);
        setYoutubeVideoID(videoId);
      }
      setStory(res.data.body);

      if (res.data.body.story_converted_url !== "") {
        setHLSVIDEOPLAYER(true);
      } else {
        setHLSVIDEOPLAYER(false);
      }

      //setLanguageStory(res.data.body.stories);
    } catch (err) {
      console.log(err.message);
      toast.error(err.message);
    }
  };

  //   const loadStoriesBasedOnCategoryHandler = async (categoryId, storyPageNo) => {
  //     setLoading(true);
  //     const url = `${process.env.REACT_APP_BACKEND_URL}/api/explore/getCategoryBasedFreeStories`;
  //     const data = {
  //       category_id: categoryId,
  //       load: storyPageNo,
  //     };
  //     try {
  //       let res = await getDataPostWithOutHeader(url, data);
  //       setStoryPage(storyPageNo + 1);
  //       if (storyPageNo > 1) {
  //         dispatch({
  //           type: "cat_stories_append",
  //           payload: res.data.body.getStoryModel,
  //         });
  //       } else {
  //         dispatch({ type: "cat_stories", payload: res.data.body });
  //       }
  //       setTotalStoryPage(res.data.body.Total_Page);
  //       setLoading(false);
  //     } catch (err) {
  //       setLoading(false);
  //       console.log(err.message);
  //       toast.error(err.message);
  //     }
  //   };

  const langaugeHandler = (i) => {
    if (i >= 0) setIndex(i);

    document.querySelectorAll(".profile-bio-tab").forEach((div) => {
      div.classList.remove("language-select-border");
    });
    document
      .getElementById(`lang_list_${i}`)
      .classList.add("language-select-border");
  };

  function getId(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11 ? match[2] : null;
  }

  return (
    <>
      <div className="container">
        <div className="row mt-2 mr-5 ml-5 no-margin-mobile-left no-margin-mobile-right">
          <div className="col-md-9">
            {story._id && story.story_type == "video" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
                className={`${
                  story.aspectRatio !== "16:9" && "height-video-story"
                }`}
              >
                {story.story_converted_url !== "" &&
                  HLSVIDEOPLAYER &&
                  story.video_encode_processed &&
                  story.video_encode_process_status === "completed" && (
                    <HlsPlayer
                      url={story.story_converted_url}
                      setHLSVIDEOPLAYER={setHLSVIDEOPLAYER}
                    />
                  )}
                {story.story_url_quality_versions.url_720 !== "" &&
                  !HLSVIDEOPLAYER &&
                  story.video_encode_processed && (
                    <VPlayer qualities={qualities} />
                  )}

                {!story.video_encode_processed && (
                  <div
                    style={{
                      width: "100%",
                      height: 0,
                      paddingBottom: "56.25%", // 16:9 Aspect Ratio
                      position: "relative",
                      backgroundColor: "#F6F6F6", // Just for visibility
                    }}
                  >
                    <NormalVDPlayer story_url={story.story_url} />
                  </div>
                )}

                {story.story_converted_url === "" &&
                  story.story_url_quality_versions.url_360 === "" &&
                  story.video_encode_processed && (
                    <div
                      style={{
                        width: "100%",
                        height: 0,
                        paddingBottom: "56.25%", // 16:9 Aspect Ratio
                        position: "relative",
                        backgroundColor: "#F6F6F6", // Just for visibility
                      }}
                    >
                      <NormalVDPlayer story_url={story.story_url} />
                    </div>
                  )}
              </div>
            )}
            {story._id && story.story_type == "text" && (
              <>
                {story.thumbnail_url !== "" && (
                  <div className="image-warapper-ratio-adujestment-user-story">
                    <img
                      style={{
                        objectFit: "contain",
                        width: "100%",
                      }}
                      className="image-whereplayer-shows-user-story"
                      src={story.thumbnail_url}
                      alt="im-story"
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = defualtImg;
                      }}
                    />
                  </div>
                )}
              </>
            )}
            {story._id && story.story_type == "image" && (
              <>
                <div className="image-warapper-ratio-adujestment-user-story">
                  <img
                    style={{
                      objectFit: "contain",
                      width: "100%",
                    }}
                    className="image-whereplayer-shows-user-story"
                    src={story.thumbnail_url}
                    alt="im-story"
                    onError={(e) => {
                      e.target.onError = null;
                      e.target.src = defualtImg;
                    }}
                  />
                </div>
              </>
            )}
            {story._id && story.story_type == "stafs" && (
              <>
                <div className="image-warapper-ratio-adujestment-user-story">
                  <img
                    style={{
                      objectFit: "contain",
                      width: "100%",
                    }}
                    className="image-whereplayer-shows-user-story"
                    src={story.thumbnail_url}
                    alt="im-story"
                    onError={(e) => {
                      e.target.onError = null;
                      e.target.src = defualtImg;
                    }}
                  />
                </div>
              </>
            )}
            {story._id && story.story_type == "audio" && (
              <>
                <audio
                  controls
                  src={story.story_url}
                  className="story-margin "
                  style={{ width: "100%" }}
                ></audio>
              </>
            )}
            {story._id && story.story_type == "video_url" && (
              <>
                <iframe
                  width="100%"
                  height="604"
                  src={`https://www.youtube.com/embed/${youtubeVideoId}?autoplay=1`}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <div
                  className="mt-3"
                  style={{
                    fontSize: "16px",
                    fontWeight: "400",
                    color: "black",
                  }}
                >
                  {story.story_title}
                </div>
                <hr />
              </>
            )}
            {!attachments && story._id && (
              <>
                <div
                  style={{
                    borderBottom: "1px solid rgba(0,0,0,.1)",
                  }}
                  className="mt-3"
                >
                  {/* {story._id &&
                    story.map((list, i) => {
                      if (i == 0) {
                        return (
                          <div
                            key={i}
                            id={`lang_list_${i}`}
                            className="profile-bio-tab ml-2 language-select-border"
                            onClick={(e) => {
                              langaugeHandler(i);
                            }}
                          >
                            {list.languages.name}
                          </div>
                        );
                      } else {
                        return (
                          <div
                            key={i}
                            id={`lang_list_${i}`}
                            className="profile-bio-tab ml-2"
                            onClick={(e) => {
                              langaugeHandler(i);
                            }}
                          >
                            {list.languages.name}
                          </div>
                        );
                      }
                    })} */}

                  {story.story_type === "stafs" && (
                    <div
                      className="add-attchment-tab profile-bio-tab ml-2"
                      id={`lang_list_${-1}`}
                      onClick={(e) => {
                        setAttachments(true);
                        langaugeHandler(-1);
                      }}
                    >
                      Attachments
                    </div>
                  )}
                  <div style={{ clear: "both" }}></div>
                </div>

                <>
                  <div
                    className={`${
                      story.languages.code === "ar" ? "text-right" : "text-left"
                    } mt-3`}
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "black",
                    }}
                  >
                    {story.story_title}
                  </div>

                  <div
                    className="mt-2"
                    style={{
                      fontSize: "14px",
                      fontWeight: "300",
                      color: "#737373",
                    }}
                  >
                    Posted -{" "}
                    {new Date(story.createdAt).toLocaleDateString(
                      "en-US",
                      dateoptions
                    )}
                  </div>
                </>

                <hr />

                {story._id && (
                  <div
                    className="mt-2"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    <div>
                      <Link
                        to={`/consultant-profile?user_id=${story.user_id._id}`}
                        style={{ color: "black" }}
                      >
                        <img
                          style={{
                            height: "50px",
                            width: "50px",
                            borderRadius: "50%",
                            float: "left",
                          }}
                          src={story.user_id.imageUrl}
                          alt="im-story"
                          onError={(e) => {
                            e.target.onError = null;
                            e.target.src = defualtImg;
                          }}
                        />
                        <div
                          style={{
                            fontWeight: "500",
                            color: "black",
                            marginLeft: "10px",
                            float: "left",
                          }}
                        >
                          {story.user_id.user_name}
                        </div>
                      </Link>
                      <div></div>
                      <div style={{ clear: "both" }}></div>
                    </div>
                    <div
                      className={`${
                        story.languages.code === "ar"
                          ? "text-right"
                          : "text-left"
                      } mt-3`}
                    >
                      {parse(story.story_text)}
                    </div>
                  </div>
                )}
              </>
            )}

            {attachments && user.isLogin && (
              <div className="mt-3">
                <div className="attachment-tabs ">
                  <div>Attachments</div>
                  <div>
                    <AiOutlineClose
                      onClick={() => {
                        setAttachments(false);
                      }}
                      className="cursor-pointer"
                    />
                  </div>
                </div>
                <hr />
                <UserStoryAttachments
                  story={story}
                  user={user.userData.loginUser}
                  token={user.userData.token}
                />
              </div>
            )}
          </div>
          {/* 
          <div className="col-md-3">
            {CatStories.stories.getStoryModel.length > 0 &&
              CatStories.stories.getStoryModel.map((story, i) => {
                if (story._id !== params.id)
                  if (story.story_type == "text") {
                    return (
                      <div
                        className="pb-3"
                        style={{ cursor: "pointer" }}
                        key={i}
                        onClick={() => {
                          setStory(story);
                          loadStoriesBasedOnCategoryHandler(
                            story.category_id,
                            1,
                          );
                        }}
                      >
                        <div
                          style={{
                            height: "108px",
                            width: "192px",
                            float: "left",
                            padding: "10px",
                            border: "2px solid #A7A5A6",
                          }}
                        >
                          {story.story_text.substring(0, 75) + "... "}
                          <span style={{ color: "#737373" }}>read more</span>
                        </div>
                        <div
                          style={{
                            float: "left",
                            marginLeft: "10px",
                          }}
                        >
                          <div
                            style={{
                              fontFamily: "sans-serif",
                              fontWeight: "500",
                              fontSize: "18px",
                              color: "black",
                            }}
                          >
                            {story.story_title}
                          </div>
                          <div style={{ color: "#737373" }}>
                            {story.user_id.user_name}
                          </div>
                          <div
                            style={{
                              color: "#7373730",
                              fontSize: "14px",
                              fontWeight: "300",
                            }}
                          >
                            <span>{story.read.count} Views - </span>
                            <span>{story.daysAgo} day ago </span>
                          </div>
                        </div>
                        <div style={{ clear: "both" }}></div>
                      </div>
                    );
                  } else if (story.story_type == "audio") {
                    return (
                      <div
                        className="pb-3"
                        style={{ cursor: "pointer" }}
                        key={i}
                        ca
                        onClick={() => {
                          setStory(story);
                          loadStoriesBasedOnCategoryHandler(
                            story.category_id,
                            1,
                          );
                        }}
                      >
                        <img
                          style={{
                            height: "108px",
                            objectFit: "cover",
                            width: "192px",
                            float: "left",
                          }}
                          src={story.story_url}
                          alt="im-story"
                          onError={(e) => {
                            e.target.onError = null;
                            e.target.src = defualtImg;
                          }}
                        />
                        <div
                          style={{
                            float: "left",
                            marginLeft: "10px",
                          }}
                        >
                          <div
                            style={{
                              fontFamily: "sans-serif",
                              fontWeight: "500",
                              fontSize: "18px",
                              color: "black",
                            }}
                          >
                            {story.story_title}
                          </div>
                          <div style={{ color: "#737373" }}>
                            {story.user_id.user_name}
                          </div>
                          <div
                            style={{
                              color: "#7373730",
                              fontSize: "14px",
                              fontWeight: "300",
                            }}
                          >
                            <span>{story.read.count} Views - </span>
                            <span>{story.daysAgo} day ago </span>
                          </div>
                        </div>
                        <div style={{ clear: "both" }}></div>
                      </div>
                    );
                  } else if (story.story_type == "video_url") {
                    return (
                      <div
                        className="pb-3"
                        style={{ cursor: "pointer" }}
                        key={i}
                        onClick={() => {
                          setStory(story);
                          loadStoriesBasedOnCategoryHandler(
                            story.category_id,
                            1,
                          );
                        }}
                      >
                        <ReactPlayer
                          url={story.story_url}
                          controls
                          height="108px"
                          width="192px"
                          style={{
                            float: "left",
                          }}
                        />
                        <div
                          style={{
                            float: "left",
                            marginLeft: "10px",
                          }}
                        >
                          <div
                            style={{
                              fontFamily: "sans-serif",
                              fontWeight: "500",
                              fontSize: "18px",
                              color: "black",
                            }}
                          >
                            {story.story_title}
                          </div>
                          <div style={{ color: "#737373" }}>
                            {story.user_id.user_name}
                          </div>
                          <div
                            style={{
                              color: "#7373730",
                              fontSize: "14px",
                              fontWeight: "300",
                            }}
                          >
                            <span>{story.read.count} Views - </span>
                            <span>{story.daysAgo} day ago </span>
                          </div>
                        </div>
                        <div style={{ clear: "both" }}></div>
                      </div>
                    );
                  } else if (story.story_type == "image") {
                    return (
                      <div
                        className="pb-3"
                        style={{ cursor: "pointer" }}
                        key={i}
                        onClick={() => {
                          setStory(story);
                          loadStoriesBasedOnCategoryHandler(
                            story.category_id,
                            1,
                          );
                        }}
                      >
                        <img
                          style={{
                            height: "108px",
                            objectFit: "cover",
                            width: "192px",
                            float: "left",
                          }}
                          src={story.story_url}
                          alt="im-story"
                          onError={(e) => {
                            e.target.onError = null;
                            e.target.src = defualtImg;
                          }}
                        />
                        <div
                          style={{
                            float: "left",
                            marginLeft: "10px",
                          }}
                        >
                          <div
                            style={{
                              fontFamily: "sans-serif",
                              fontWeight: "500",
                              fontSize: "18px",
                              color: "black",
                            }}
                          >
                            {story.story_title}
                          </div>
                          <div style={{ color: "#737373" }}>
                            {story.user_id.user_name}
                          </div>
                          <div
                            style={{
                              color: "#7373730",
                              fontSize: "14px",
                              fontWeight: "300",
                            }}
                          >
                            <span>{story.read.count} Views - </span>
                            <span>{story.daysAgo} day ago </span>
                          </div>
                        </div>
                        <div style={{ clear: "both" }}></div>
                      </div>
                    );
                  } else if (story.story_type == "video") {
                    return (
                      <div
                        className="pb-3"
                        style={{ cursor: "pointer" }}
                        key={i}
                        onClick={() => {
                          setStory(story);
                          loadStoriesBasedOnCategoryHandler(
                            story.category_id,
                            1,
                          );
                        }}
                      >
                        <video
                          width="100%"
                          controls
                          src={story.story_url}
                          poster={story.thumbnail_url}
                          style={{
                            float: "left",
                            height: "108px",
                            width: "192px",
                          }}
                        >
                          Your browser does not support the video tag.
                        </video>
                        <div
                          style={{
                            float: "left",
                            marginLeft: "10px",
                          }}
                        >
                          <div
                            style={{
                              fontFamily: "sans-serif",
                              fontWeight: "500",
                              fontSize: "18px",
                              color: "black",
                            }}
                          >
                            {story.story_title}
                          </div>
                          <div style={{ color: "#737373" }}>
                            {story.user_id.user_name}
                          </div>
                          <div
                            style={{
                              color: "#7373730",
                              fontSize: "14px",
                              fontWeight: "300",
                            }}
                          >
                            <span>{story.read.count} Views - </span>
                            <span>{story.daysAgo} day ago </span>
                          </div>
                        </div>
                        <div style={{ clear: "both" }}></div>
                      </div>
                    );
                  }
              })}
            {loading && <LoadingCard count={3} />}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ViewProfileStory;
