const initialState = {
  cartTotalPrice: 0,
  courses: [],
};

const CheckoutLists = (state = initialState, action) => {
  switch (action.type) {
    case "CARTCHECKOUT":
      return {
        cartTotalPrice: action.payload.cartTotalPrice,
        courses: action.payload.courses,
      };

    case "CARTCHECKOUTPRICETOTOAL":
      return {
        ...state,
        cartTotalPrice: state.cartTotalPrice + action.payload.cartTotalPrice, // Add the previous value
        courses: [...state.courses, action.payload.course],
      };

    case "CARTCHECKOUTREMOVEONE":
      const courseToRemove = state.courses.find(
        (course) => course._id === action.payload.courseId
      );

      console.log(
        courseToRemove,
        action.payload.courseId,
        "action.payload.courseId"
      );
      return {
        ...state,
        cartTotalPrice: courseToRemove
          ? state.cartTotalPrice - courseToRemove.price // Subtract price if course exists
          : state.cartTotalPrice, // Otherwise, leave the total price unchanged
        courses: state.courses.filter(
          (course) => course._id !== action.payload.courseId
        ), // Remove the course by ID
      };
    default:
      return state;
  }
};

export default CheckoutLists;
