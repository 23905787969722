import React, { useEffect, useState } from "react";
import axios from "axios";

const GetCurrencyAndValue = ({ currency_cd, value, className }) => {
  const [amount, setAmount] = useState({});
  const [laoding, setLoading] = useState(false);
  useEffect(() => {
    getCurrancyValue();
  }, []);

  const getCurrancyValue = async () => {
    setLoading(true);

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_CURRENCY_API_URL}/convert?apikey=${process.env.REACT_APP_CURRENCY_API_KEY}&currencies=${currency_cd}&base_currency=KWD&value=${value}`
      );
      console.log(
        res.data.data[currency_cd].value,
        "value",
        currency_cd,
        value
      );

      setAmount({
        value: res.data.data[currency_cd].value,
        currency_cd: currency_cd,
      });
    } catch (err) {
      console.log(err);
      alert("Currecny converter error");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className={className}>
      {laoding && (
        <span className="loader-spiner loader-spiner-additional"></span>
      )}
      {!laoding &&
        `${parseFloat(amount.value).toFixed(3)} ${amount.currency_cd}`}
    </div>
  );
};

export default GetCurrencyAndValue;
