import React, { useEffect, useState } from "react";
import { HiCreditCard } from "react-icons/hi2";
import { Spin } from "antd";
import {
  getRequestedData,
  getDataPostWithHeader,
} from "../functions/editProfile/editProfile";
import { useDispatch, useSelector } from "react-redux";
import visacard from "../images/visacard.png";
import mastercard from "../images/mastercard.png";
import KNETLogo from "../images/KNETLogo.svg";
import discovercard from "../images/discovercard.png";
import americanexpresscard from "../images/americanexpresscard.png";
import "../css/checkout.css";

const CheckOut = () => {
  const [loading, setLoading] = useState(false);
  const [countries, setCountires] = useState([]);
  const [paymentType, setPaymentType] = useState("");
  const [country, setCountry] = useState("0");
  const {
    CheckoutLists,
    BasicUserReducers: { user },
  } = useSelector((state) => state);

  useEffect(() => {
    getCountries();
  }, []);

  console.log(CheckoutLists, "CheckoutLists");

  const getCountries = async () => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/users/getCountries`;

      const res = await getRequestedData(url);

      setCountires(res.data.body);
    } catch (err) {
      console.log(err);
      alert(err.message);
    }
  };

  const initiateChekout = async (paymenttype) => {
    setLoading(true);
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/checkout/checkout-initiate`;
    const data = {
      user_id: user.loginUser._id,
      payment_type: paymenttype,
      tokenparam: user.token,
    };
    try {
      let res = await getDataPostWithHeader(url, data, user.token);

      if (paymenttype === "Card") {
        window.Checkout.configure({
          session: {
            id: res.data.body.redirect,
          },
        });
        window.Checkout.showEmbeddedPage("#embed-target");
      } else {
        console.log(res.data.body);
        window.location.href = res.data.body.redirect;
      }

      console.log(res.data.body.session_id);
    } catch (err) {
      console.log(err.message);
      alert(err.message);
    }
    setLoading(false);
  };

  // const handleCart = async () => {
  //   let userData = "";
  //   if (typeof Storage !== "undefined") {
  //     if (localStorage.getItem("userDataSessionWay")) {
  //       userData = JSON.parse(localStorage.getItem("userDataSessionWay"));
  //     }
  //   } else {
  //     alert("Please update your browser");
  //   }
  //   setLoading(true);

  //   const subs = carts.map((cart) => {
  //     return cart.course_id._id;
  //   });

  //   const data = {
  //     user_id: userData.loginUser._id,
  //     subscription_id: subs,
  //   };

  //   try {
  //     let url = `${process.env.REACT_APP_BACKEND_URL}/api/userProfile/purchaseSubscriptionGroups`;
  //     let res = await getDataPostWithHeader(url, data, userData.token);
  //   } catch (err) {
  //     console.log(err.message);
  //     alert(err.message);
  //   }
  //   setLoading(false);
  // };

  const handlePaymentType = async (e) => {
    setPaymentType(e.target.value);
    if (e.target.value === "Cards") {
      await initiateChekout("Card");
    } else {
      setLoading(true);
      await initiateChekout("Knet");
    }
  };

  return (
    <div className="checkout-wraper">
      <div className="checkout-payment-wrapper">
        <div className="checkout-title">CheckOut</div>

        <div className="checkout-title">Billing Address</div>
        <div className="chekout-country mt-1">
          <div className="display-flex flex-space-btw checkout-requireds p-1">
            <div>Country</div>
            <div>Required</div>
          </div>

          <div>
            <select
              className="form-control mt-2"
              defaultValue={country}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
            >
              <option value="0">Select Country</option>
              {countries.length > 0 &&
                countries.map((country) => {
                  return (
                    <option value={country._id} key={country._id}>
                      {country.country_english}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>

        <div className="payment-wrapper">
          <div className="flex-space-btw display-flex-align-center mb-1">
            <div className="payment-title ">Payment method</div>
            <div className="checkout-notes">Secured connection</div>
          </div>
          <div className="payment-details">
            <div
              className={`payment-type-wrapper border-bottom-payment
              `}
            >
              <div className="display-flex">
                <input
                  type="radio"
                  name="payment"
                  value="Cards"
                  className="mr-1"
                  onChange={handlePaymentType}
                />
                <label for="Cards" className="display-flex-align-center">
                  <HiCreditCard size={32} className="mr-1 HiCreditCard" />
                  Credit/Debit card
                </label>
              </div>
              <div className="display-flex">
                <input
                  type="radio"
                  name="payment"
                  value="Knet"
                  className="mr-1"
                  onChange={handlePaymentType}
                />
                <label for="Knet" className="display-flex-align-center">
                  <img src={KNETLogo} alt="knet" className="knet-card mr-1" />
                  Knet
                </label>
              </div>
            </div>
            <div
              style={{
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingBottom: "20px",
              }}
            >
              {loading && <Spin />}
              <div
                id="embed-target"
                className={`${
                  paymentType === "Knet" ? "display-none" : "display-block"
                }`}
              ></div>
            </div>
            {paymentType == "Cards" && (
              // <div className="p-4">
              //   <div className="w-100 mb-1">
              //     <div className="display-flex flex-space-btw checkout-requireds p-1">
              //       <div>Name On the Card</div>
              //       <div className="checkout-notes">Required</div>
              //     </div>
              //     <input
              //       type="text"
              //       name="payment"
              //       className="form-control frominputs "
              //       value=""
              //       onChange={(e) => setPaymentType(e.target.value)}
              //     />
              //   </div>

              //   <div className="w-100 mb-1">
              //     <div className="display-flex flex-space-btw checkout-requireds p-1">
              //       <div>Card Number</div>
              //       <div className="checkout-notes">Required</div>
              //     </div>
              //     <input
              //       type="text"
              //       name="payment"
              //       className="form-control frominputs "
              //       value=""
              //       onChange={(e) => setPaymentType(e.target.value)}
              //     />
              //   </div>

              //   <div className="w-100 mb-1 display-flex">
              //     <div className="width-50 p-1">
              //       <div className="display-flex flex-space-btw checkout-requireds p-1">
              //         <div>Card Number</div>
              //         <div className="checkout-notes">Required</div>
              //       </div>
              //       <input
              //         type="text"
              //         name="payment"
              //         className="form-control frominputs "
              //         value=""
              //         onChange={(e) => setPaymentType(e.target.value)}
              //       />
              //     </div>

              //     <div className="width-50 p-1">
              //       <div className="display-flex flex-space-btw checkout-requireds p-1">
              //         <div>Card Number</div>
              //         <div className="checkout-notes">Required</div>
              //       </div>
              //       <input
              //         type="text"
              //         name="payment"
              //         className="form-control frominputs "
              //         value=""
              //         onChange={(e) => setPaymentType(e.target.value)}
              //       />
              //     </div>
              //   </div>
              // </div>
              <div></div>
            )}
            {/* <div
              className={`payment-type-wrapper ${
                paymentType == "Knet" && "border-bottom-payment"
              }`}
            >
              <div className="display-flex">
                <input
                  type="radio"
                  name="payment"
                  value="Knet"
                  className="mr-1"
                  onChange={handlePaymentType}
                />
                <label for="Knet" className="display-flex-align-center">
                  <img src={KNETLogo} alt="knet" className="knet-card mr-1" />
                  Knet
                </label>
              </div>
            </div> */}
            {paymentType == "Knet" && (
              <>
                {loading && (
                  <div>
                    <Spin />
                    Knet Page laoding
                  </div>
                )}
              </>
              // <div className="p-4">
              //   <div className="w-100 mb-1">
              //     <div className="display-flex flex-space-btw checkout-requireds p-1">
              //       <div>Card Number</div>
              //       <div className="checkout-notes">Required</div>
              //     </div>
              //     <input
              //       type="text"
              //       name="payment"
              //       className="form-control frominputs "
              //       value=""
              //       onChange={(e) => setPaymentType(e.target.value)}
              //     />
              //   </div>

              //   <div className="w-100 mb-1">
              //     <div className="display-flex flex-space-btw checkout-requireds p-1">
              //       <div>Pin</div>
              //       <div className="checkout-notes">Required</div>
              //     </div>
              //     <input
              //       type="text"
              //       name="payment"
              //       className="form-control frominputs "
              //       value=""
              //       onChange={(e) => setPaymentType(e.target.value)}
              //     />
              //   </div>
              // </div>
            )}
          </div>
        </div>

        <div className="summarry-payment-wrapper">
          <div className="sumary-wrapper">
            <div className="checkout-title">Summary</div>

            {CheckoutLists.discountPrice && (
              <div>
                <div className="flex-space-btw mt-2">
                  <div>Origanl Price</div>
                  <div>
                    {CheckoutLists.cartTotalPrice &&
                      CheckoutLists.cartTotalPrice}
                  </div>
                </div>
                <div className="flex-space-btw mt-2">
                  <div>Discount Price</div>
                  <div>{0}</div>
                </div>
                <hr />
              </div>
            )}

            <div className="flex-space-btw mt-2 summary-total ">
              <div>Total</div>
              <div>
                {CheckoutLists.cartTotalPrice &&
                  parseFloat(CheckoutLists.cartTotalPrice).toFixed(3)}{" "}
                {CheckoutLists.courses[0].currency_symbol}
              </div>
            </div>

            <div className="mt-4 checkout-notes">
              By completing your purchase you agree to these Terms of Service.
            </div>
            {/* 
            <button
              to="/payment/checkout"
              className="btn btn-info btn-text-cenetr mt-4"
              style={{ width: "100%" }}
            >
              Complete checkout
              {loading && (
                <span className="loader-spiner loader-spiner-addtionl"></span>
              )}
            </button> */}
          </div>
        </div>

        <div className="mt-3">
          <div className="checkout-title">Order details</div>
          <div className="mt-2">
            {CheckoutLists.courses.map((course) => {
              return (
                <div className="flex-space-btw checkout-requireds">
                  <div className="display-flex course-img-tit-wrapper">
                    <div>
                      {course.group_video_thumbnail_url && (
                        <img
                          src={course.group_video_thumbnail_url}
                          alt="course"
                          className="course-imge"
                        />
                      )}
                    </div>
                    <div>{course.group_name}</div>
                  </div>
                  <div className="display-flex course-img-tit-wrapper ">
                    <div>
                      {parseFloat(course.subscription_price).toFixed(3)}
                    </div>
                    <div>{course.currency_symbol}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckOut;
